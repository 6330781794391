import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageFull } from '../../components/imageelements'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'
import { QandA } from '../../components/qanda'

// markup
const RoyalOperaPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>The Royal Opera House | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operahouses/royalopera" />
          <meta name="description" content="The Royal Opera House in Covent Garden, London. A guide and FAQ for Britain's most important opera company, including how to get tickets and what to wear!" />
        </Helmet>
        <Hero title="The Royal Opera House"/>
        <MainBodySection>

        <Header title="The Royal Opera"/>

        <p>The Royal Opera is one of the world’s leading opera companies and the most important in London. The current music director is Antonio Pappano with Oliver Mears the Director of Opera. The Orchestra of the Royal Opera House is shared with the Royal Ballet and the company has a large permanent chorus.</p>
        
        <div id="ezoic-pub-ad-placeholder-102"> </div>

        <p>The company presents around 18-20 different operas a year with no single focus, albeit a slight preference towards 19th Century works. Audience tastes lie somewhere between the USA and Europe, strongly traditional productions mixing with starkly contemporary ones. The average audience age is high but that is somewhat dictated by the inevitably high price of the tickets.</p>

        <ImageFull>
          <StaticImage src="../../images/operahouses/royalopera/Popsy_CatherineAshmore.jpeg" alt="Marina Poplavskaya in Otello"/>
          <figcaption>Marina Poplavskaya as Desdemona in the Royal Opera's <i>Otello</i> &copy; Catherine Ashmore</figcaption>
        </ImageFull>

        <p>The Royal Opera have an in-house programme for young performers, the Jette Parker Young Artists Programme. This gives young singers and directors full-time contracts for two years, during which they take on small roles in main stage productions (and cover much larger ones) whilst furthering their musical development. It has been hugely successful with many going on to international careers including Marina Poplavskaya, Ekaterina Gubanova and Matthew Rose to name but three.</p>

    </MainBodySection>

    <div id="ezoic-pub-ad-placeholder-103"> </div>

    <AdContainer>
      <AdBanner
        style={{ display: 'block' }}
        slot="3461570696"
        format="auto"
        responsive="true"
      />
    </AdContainer>

    <MainBodySection> 

        <Header title="The Royal Ballet"/>
                    
        <p>The Royal Ballet was founded in 1931 by Dame Ninette de Valois and has been one of the world’s top companies throughout its existence. They play a prominent role at the Royal Opera House, sharing the stage each week with the opera company though seldom at the same performances. The Royal Ballet is led by Kevin O'Hare with Wayne McGregor (originally a contemporary choreographer) the Resident Choreographer.</p>

        <p>Balancing a rich heritage with more modern fare, The Royal Ballet are not infrequently accused of safe programming (somewhat justifiably). A wealth of <em>Swan Lakes</em> and <em>Nutcrackers</em> every season with new work largely kept to 2-3 new short ballets a year. This was somewhat remedied with the successful premieres of Christopher Wheeldon’s <i>Alice’s Adventures in Wonderland</i>, 2011 and <i>The Winter's Tale</i>, 2014 but the emphasis is still largely on the classics. The company presents around 7-8 full length works and 5-6 mixed bills a year sharing the main stage with the opera company.</p>

        <ImageFull>
          <StaticImage src="../../images/operahouses/royalopera/Cutherbertson_Alice.jpeg" alt="Lauren Cuthbertson as Alice"/>
          <figcaption>Lauren Cuthbertson as Alice in the Royal Ballet's <i>Alice's Adventures in Wonderland</i> &copy; Johan Persson</figcaption>
        </ImageFull>

        <Header title="FAQ: Attending the Royal Opera House"/>
        
        <QandA>
            <li>
                <h4>Is there a Dress code?</h4>

                <p>No there most definitely isn’t. You’ll see a range of clothing on show from flamboyant black tie to decidedly tatty jumpers. If you feel like dressing up then go for it but I’ve pitched up many times in dirty jeans and a t-shirt without raising any eyebrows.</p>
            </li>

            <li>
                <h4>Programmes and Information?</h4>

                <p>Programmes are pricey at £7 (£6 for ballet) but have a fair bit of content. You get vastly more for your money than you do in a normal £3.50 West End one. They contain a detailed synopsis, four or five solid essays (mostly interesting and informative) and full cast biographies. Free cast sheets are available as well (often very obvious but increasingly hidden over the last few years).</p>
            </li>

            <li>
                <h4>Cloakroom?</h4>

                <p>A large, well staffed and free cloakroom is available for all. They’ll take pretty much anything (they frown at bikes but I’ve seen them accept them) and the queues before/after are minimal. It can be a bit of a scrum post-show but only for a minute or so.</p>
            </li>

            <li>
                <h4>Can I get in late?</h4>

                <p>I’m afraid this is a big no-no. They’ll show you to an area where you can watch on screens, really pretty small screens as it happens, but you won’t get into the auditorium till a suitable break. This can be more than an hour in many cases (or indeed the whole performance in a few) so it’s probably best just to arrive on time! The only exception is if you're seated in a box, these can usually be accessed late (as they're totally private).</p>
            </li>

            <li>
                <h4>Where Can I Buy Tickets?</h4>

                <p>Tickets can be bought by a variety of means but the standard three are: in person at the box office, over the phone (0207-304-4000) or on the Royal Opera’s website (<a href="http://www.roh.org.uk/" title="The Website of the Royal Opera House">http://www.roh.org.uk/</a>). Tickets go on sale in batches across the season and the hot tickets sell out pretty much on the day of release.</p>
            </li>

            <li>
                <h4>Sold Out Shows?</h4>

                <p>Productions often sell out far in advance but there are ways of getting tickets. The most guaranteed method is day seats. 67 are available, most at the back of the amphitheatre with a few standing places and some extreme sides of the stalls circle. They go on sale at 10am on the day of the performance and queues start early for the most popular events.</p>

                <p>If that isn’t an option then simply checking the website regularly can work. They put returns online and with some shows selling out months before the performance plenty do turn up. No guarantees of course, and the tickets are often the more expensive ones, but I’ve had plenty of luck this way.</p>

                <p>Lastly heading to third party websites can work. I wouldn’t recommend touts, a dodgy lot at the best of times, but you’d be amazed what turns up on ebay. There are a few forums, largely filled with opera/ballet fanatics, that have posts with people offering tickets they can’t use. All this is at your own risk of course but for the desperate it’s quite possible.</p>
            </li>

        </QandA>

    </MainBodySection>

    <div id="ezoic-pub-ad-placeholder-104"> </div>

    <AdContainer>
      <AdBanner
        style={{ display: 'block' }}
        slot="3461570696"
        format="auto"
        responsive="true"
      />
    </AdContainer>

    <MainBodySection> 


        <Header title="FAQ: Cheap Tickets!"/>
        
        <QandA>

            <li>
                <h4>Are all the seats expensive?</h4>

                <p>This is a bit of a yes and no. For opera the bulk of the full view seats are pretty expensive. Bottom end is about £30-£40 and that’s for a seat right at the back of the amphitheatre (pack your binoculars). Top end can be as much as £200+.</p>
            </li>

            <li>
                <h4>Reasonably priced seats</h4>

                <p>Top price tickets are painfully expensive, but the Royal Opera House is an old theatre and this means there are lots of seats further from the stage with less good views (though arguably better acoustics). The slips are much cheaper than any other section, £10-£15, but the sight-lines are pretty terrible, justifiably labelled “restricted view”. The back of the amphitheatre gives you a full view, albeit from far away, and these tickets go for between £20-£40 depending on the show. Trade secret: V61 in the amphitheatre is a gem, labelled restricted view and priced down as a result but the view is only very, very slightly affected by a pillar. You’re right at the back but at least you can see the whole stage.</p>

                <p>For the more stoic, standing is a cheap option (and our favoured one). Prices are very reasonable at £5-£15 and the ones in the centre of the stalls circle and balcony offer excellent views (the seat in front of you costs £100+). These are unfortunately extremely difficult to get a hold of because there aren’t very many and they’re extremely popular. Still an absolute bargain if you chance upon them.</p>
            </li>

            <li>
                <h4>Discounts?</h4>

                <p>Good luck to you. The Royal Opera do not discount very often (they don’t have to). The pricing structure does vary hugely however: the Romantic operatic warhorses are monstrously expensive but contemporary opera is frequently sold with a significantly lower top price.</p>
            </li>

            <li>
                <h4>Student Deals?</h4>

                <p>The Royal Opera House has a fairly good young persons scheme (you can <a href="https://www.roh.org.uk/ticket-deals/young-roh">find it here</a>). 200 seats are made available at £25 to under 25s for each weeknight performance (excluding some big hitters like 'The Nutcracker'). A great deal!</p>
            </li>

        </QandA>

        </MainBodySection>
    </Layout>
  )
}

export default RoyalOperaPage
